import Vue from 'vue';
import { Select, Option } from 'element-ui';
import App from './App.vue';
import router from './router'; // 路由
import store from './store/index'; // vuex
import '@/assets/css/normalize.css';
import '@/assets/scss/setUp.scss';
import '@/utils/setInit'; // 浏览器指纹 获取用户所在国家
import { v3 } from '@/api'; // vuex
import VueI18n from 'vue-i18n';
import { messages } from '@/assets/js/data'; // 语言包
import findKey from 'lodash/findKey.js'; // 多语言相关组件
import { isMobile } from '@/utils/tool';
import Cookies from 'js-cookie';
import QRCode from 'qrcodejs2';
import * as echarts from 'echarts';

Vue.use(Select);
Vue.use(Option);
Vue.config.productionTip = false;
const offsetGMT = new Date().getTimezoneOffset() * 60 * 1000;
const now = new Date().getTime() - offsetGMT;
const mobile = !isMobile();
store.commit('base/set_isMobile', mobile);

let lang = navigator.language.split(',')[0].toLowerCase();
if (lang != 'zh-cn' && lang.includes('-')) {
  lang = lang.split('-')[0];
}
if (!store.state.base.langList.includes(lang)) {
  lang = 'en'; //如果不在语种中则兜底英语
}
// lang = 'ru';
store.commit('base/setLangCode', lang);
console.log('lang::: 浏览器语言', lang);

Vue.use(VueI18n);
Vue.prototype.$cookies = Cookies; // 设置cookie
Vue.prototype.$store = store; // vuex
Vue.prototype.$v3 = v3; // vuex
Vue.prototype.$QRCode = QRCode; // vuex

Vue.prototype.$echarts = echarts; // vuex

const commit = Vue.prototype.$store;
// 处理域名
if (window.location.host.includes('.com')) {
  // 设置获取主域名
  const host = window.location.host.split('.').splice(1, 2);
  commit.commit('base/sethost', host.join('.'));
}
Vue.prototype.momentTime = (timestamp, type = 0) => {
  // timestamp传时间戳--格式化时间type为0默认，实堪=1；快讯为2；财经日历为3
  if (!timestamp) return;
  if (String(timestamp).includes('T')) {
    timestamp = Date.parse(timestamp);
  }
  if (`${timestamp}`.length < 12) timestamp = timestamp * 1000;
  const timer = new Date(timestamp - offsetGMT);
  const yyyy = timer.getUTCFullYear(); // 年
  const mm = timer.getUTCMonth() + 1 > 9 ? timer.getUTCMonth() + 1 : '0' + (timer.getUTCMonth() + 1); // 月
  const dd = timer.getUTCDate() > 9 ? timer.getUTCDate() : '0' + timer.getUTCDate(); // 日
  const hh = timer.getUTCHours() > 9 ? timer.getUTCHours() : '0' + timer.getUTCHours(); // 时
  const min = timer.getUTCMinutes() > 9 ? timer.getUTCMinutes() : '0' + timer.getUTCMinutes(); // 分
  const ss = timer.getUTCSeconds() > 9 ? timer.getUTCSeconds() : '0' + timer.getUTCSeconds(); // 秒
  let time = '';
  if (type == 0) {
    const lang = this.$store.state.base.LanguageCode;
    if (lang == 'zh-cn' || lang == 'zh-tw' || lang == 'zh-hk') {
      const momen = now - timer;
      switch (true) {
        case momen >= 0 && momen < 600000:
          time = this.$v('刚刚');
          break;
        case momen >= 600000 && momen < 3600000:
          time = this.$v('{0}分钟前').replace('{0}', Math.round(momen / 60000));
          break;
        case momen >= 3600000 && momen < 86400000:
          time = this.$v('{0}小时前').replace('{0}', Math.round(momen / 3600000));
          break;
        case momen >= 86400000 && momen < 259200000:
          time = this.$v('{0}天前').replace('{0}', Math.round(momen / 86400000));
          break;
        case momen >= 259200000 && momen < 604800000:
          time = this.$v('{0}周内').replace('{0}', 1);
          break;
        default:
          time = `${yyyy}-${mm}-${dd} ${hh}:${min}`;
          break;
      }
    } else {
      time = `${yyyy}-${mm}-${dd} ${hh}:${min}`;
    }
  } else if (type == 1) {
    time = `${yyyy}-${mm}-${dd}`;
  } else if (type == 2) {
    time = `${hh}:${min}:${ss}`;
  } else if (type == 3) {
    time = `${hh}:${min}`;
  } else if (type == 4) {
    time = `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
  }
  return time;
};
const i18n = new VueI18n({
  locale: lang,
  messages,
  silentTranslationWarn: true
});
VueI18n.prototype.translation = function (value) {
  let en = require('@/assets/languages/TXT_zh-CN.json');
  let val = findKey(en, function (o) {
    return o === value;
  });
  if (!val) {
    return value + '(缺翻译)';
  }
  return this.$t(val);
};
Vue.prototype.$v = i18n.translation; // i18n
document.querySelector('html').setAttribute('lang', lang);
i18n.location = lang;
if (lang === 'ar') {
  document.querySelector('html').setAttribute('dir', 'rtl');
} else {
  document.querySelector('html').setAttribute('dir', 'ltr');
}
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
