import store from '@/store';
/**
 *
 * @param {*} time 时间戳
 * @returns string
 */
export function TimeFormatting(time) {
  const l = store.state.base.LanguageCode.toLowerCase();
  const now = Date.now();
  const diff = now - time;

  if (diff < 600000) {
    // 10分钟内
    if (l == 'zh-cn') return '刚刚';
    else if (l == 'zh') return '剛剛';
    else return `${Math.ceil(diff / 60000)}m`;
  } else if (diff < 3600000) {
    // 1小时内
    const minutes = Math.floor(diff / 60000);
    if (l == 'zh-cn') return `${minutes}分钟前`;
    else if (l == 'zh') return `${minutes}分鐘前`;
    else return `${Math.ceil(diff / 60000)}m`;
  } else if (diff < 86400000) {
    // 1天内
    const hours = Math.floor(diff / 3600000);
    if (l == 'zh-cn') return `${hours}小时前`;
    else if (l == 'zh') return `${hours}小時前`;
    else return `${Math.ceil(diff / 3600000)}h`;
  } else if (diff < 604800000) {
    const days = Math.floor(diff / 86400000);
    console.log('days::: ', days);
    if (days === 1) {
      if (l == 'zh-cn' || l == 'zh') return '昨天';
      else return `Yesterday`;
    } else if (days === 2) {
      if (l == 'zh-cn' || l == 'zh') return '2天前';
      else return `Two days ago`;
    } else if (days === 3) {
      if (l == 'zh-cn' || l == 'zh') return '3天前';
      else return `Three days ago`;
    } else {
      if (l == 'zh-cn') return '1周内';
      else if (l == 'zh') return `1周內`;
      else return `In a week`;
    }
  } else {
    // 超过8天并且年份在当前年份内
    const date = new Date(time);
    const currentYear = new Date().getFullYear();
    const year = date.getFullYear();
    const yearDiff = currentYear - year;
    if (yearDiff === 0) {
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${month}-${day}`;
    } else {
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${year}-${month}-${day}`;
    }
  }
}

export function isMobile() {
  var userAgentInfo = navigator.userAgent;
  var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}

export function changeTitle(title) {
  let dom = document.querySelector('title');
  if (dom) {
    dom.innerText = title;
  }
}
export function changeKeywords(title) {
  let dom = document.querySelectorAll('meta')[3];
  if (dom) {
    dom.setAttribute('content', title);
  }
}
export function changeDescription(title) {
  let dom = document.querySelectorAll('meta')[4];
  if (dom) {
    dom.setAttribute('content', title);
  }
}

// 数字千分位展示
export function num2CurrencyNo (value = 0) {
  if (!value) return 0
  const intPart = Math.trunc(value) // 获取整数部分
  const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 整数部分处理，增加,
  let floatPart = '' // 预定义小数部分
  const valueArray = value.toString().split('.') // 将数值截取为小数部分和整数部分
  if (valueArray.length == 2) { // 有小数部分
    floatPart = valueArray[1].toString() // 取得小数部分
    return intPartFormat + '.' + floatPart
  }
  return intPartFormat + floatPart
}
