<template>
  <div id="app">
    <keep-alive>
      <router-view :key="$route.fullPath"></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {

  }
};
</script>

<style scoped lang="scss">
#app {
  padding-top: 80px;
  @media screen and (max-width: 1199px) {
    padding-top: 77px;
  }
  @media screen and (max-width: 799px) {
    padding-top: 62px;
  }
}
</style>
