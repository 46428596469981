import request from '@/utils/request';
const webUrl = '/wikifxweb-v1/invoke-v3/';
// const ipTranslet= 'https://exportedbase.interface003.com', // 获取用户真实ip地址+ip所在国家
export function v3(data, headers) {
  const o = Object.values(headers);
  const v = o[0].split('$')[0];
  return request({
    url: webUrl + v,
    method: 'post',
    data,
    headers
  });
}

// 获取新闻数据
export function getNewsList(params) {
  return request({
    url: `/wikifxweb-v1/dealer/detail/intromodule-v1`,
    method: 'get',
    params
  });
}
// 获取Ad数据
export function getMain(params) {
  return request({
    url: `wikifxweb-v1/dealer/detail/mainmodule-v1`,
    method: 'get',
    params
  });
}

// 获取下载页数据
export function downloadData(params) {
  return request({
    url: `wikifxweb-v1/download/index/init-v1`,
    method: 'get',
    params
  });
}
export function getTips(params) {
  return request({
    url: `wikifxweb-v1/wikifx/trader/tips-v1`,
    method: 'get',
    params
  });
}

export function getStatistics(data) {
  return request({
    url: `wikifxweb-v1/search/detail/statistics-v1`,
    method: 'post',
    data
  });
}

export function getVps(params) {
  return request({
    url: `wikifxweb-v1/dealer/detail/vps-v1`,
    method: 'get',
    params
  });
}

// 获取市场策略
export function getResearch(params) {
  return request({
    url: `/wikifxweb-v1/wikifx/MarketAnalysis-v1`,
    method: 'get',
    params
  });
}

//屏蔽点位接口

export function getRiskcontrol(params) {
  return request({
    url: `wikifxweb-v1/riskcontrol-v1`,
    method: 'get',
    params
  });
}

// 获取历史
export function getHistory(params) {
  return request({
    url:`/wikifxweb-v1/dealer/detail/data/history-v1`,
    method:'get',
    params
  });
}

export function getDataInit(params) {
  return request({
    url: `/wikifxweb-v1/dealer/detail/data/init-v1`,
    method:'get',
    params
  })
}

export function getDetailHistory(params) {
  return request({
    url: `/wikifxweb-v1/dealer/detail/history/init-v1`,
    method: 'get',
    params
  })
}
export function getSkyeyemodule(params) {
  return request({
    url: `/wikifxweb-v1/dealer/detail/skyeyemodule-v1`,
    method: 'get',
    params
})
}

// 获取交易环境数据
export function getBroker(params) {
  return request({
    url: `wikifxweb-v1/dealer/detail/vps/broker-v1`,
    method: 'get',
    params
  })
}


export function relatedsoftwarelist(params) {
  return request({
    url: `/wikifxweb-v1/dealer/software/list-v1`,
    method: 'get',
    params
  });
}
export function accountmodule(params) {
  return request({
    url: `/wikifxweb-v1/dealer/detail/accountmodule-v1`,
    method: 'get',
    params
  });
}

// 获取天眼测评数据
export function getSkyeyescore(params) {
  return request({
    url: `wikifxweb-v1/dealer/detail/skyeyescore-v2`,
    method: 'get',
    params
  })
}

export function getSkyeyeriskreport(params) {
  return request({
    url: `wikifxweb-v1/dealer/detail/skyeyeriskreport-v1`,
    method: 'get',
    params
  })
}

