import Fingerprint2 from 'fingerprintjs2';
import $cookies from 'js-cookie';
import axios from 'axios';
import store from '@/store';

// 设置全局
const host = location.host.includes('aws') ? location.host.replace('aws.', '') : location.host; // 获取域名不带www前缀
let cookieHost = host
  ? host.includes('7667')
    ? host.split(':')[0]
    : host.includes('443')
    ? host.split(':')[0].substr(host.indexOf('.'))
    : host.substr(host.indexOf('.'))
  : '.wikifx.com';
cookieHost = cookieHost.includes('aws') ? cookieHost.replace('aws.', '') : cookieHost;
store.commit('base/setcookieHost', cookieHost); // 处理好存cookie需要的路径域名

function setBfp() {
  // 浏览器指纹码
  Fingerprint2.get((components) => {
    // 浏览器指纹码
    const values = components.map(function (component, index) {
      if (index === 0) {
        // 把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
        return component.value.replace(/\bNetType\/\w+\b/, '');
      }
      return component.value;
    });
    // 生成最终id murmur
    const murmur = Fingerprint2.x64hash128(values.join(''), 31);
    const now = new Date(new Date(new Date().getTime()).setFullYear(9998, 0, 0));
    const host = window.location.host;
    $cookies.set('wiki-bfp', murmur, { expires: now, path: '/' });
    localStorage.setItem('wiki-bfp', murmur, { expires: now, path: '/' });
  });
}

// async function getCookieCountryCode() {
//   // 获取国家取值
//   const req = navigator;
//   const CountryCode = 'CountryCode';
//   if ($cookies.get(CountryCode)) {
//     console.log($cookies.get(CountryCode),'$cookies.get(CountryCode)');
//     // 取国家
//     return $cookies.get(CountryCode);
//   } else {
//     const arr = req.userAgent && req.userAgent.split('|')[1] ? req.userAgent.split('|')[1].split(',') : [];
//     const arrCoun = arr[1];
//     if (arrCoun && arrCoun.split(':')[1]) {
//       return arrCoun.split(':')[1] ? arrCoun.split(':')[1] : 'us';
//     } else {
//       console.log(store.state.base.userIp,'store.ip');
//       const forwardedIp = req['x-forwarded-for'] ? req['x-forwarded-for'].split(',')[0] : '114.114.114.114';
//       const user_country_code = await axios.get(`https://exportedbase.interface003.com/ipgeo/getcity?ip=${forwardedIp}`)
//         .then((res) => {
//           console.log(res.data.result, '地址')
//           return res.data.result.country_code;
//         })
//         .catch(() => {
//           console.log('user_country_code错误');
//         });
//       const country = user_country_code || 'cn';
//       return country;
//     }
//   }
// }
// async function setcountryInfo() {
//   let country = await getCookieCountryCode();
//   country = country.toLowerCase();
//   country = country === 'gb' ? 'uk' : country;
//   let countryArr = [];
//   const lang = store.state.base.LanguageCode;
//   if (lang === 'zh-cn' || lang === 'zh-tw' || lang === 'zh-hk' || lang === 'zh') {
//     countryArr = require('@/static/json/areaCodeCN.json');
//   } else {
//     countryArr = require('@/static/json/areaCodeEn.json');
//   }
//   let countryInfo = {};
//   countryArr.forEach((item) => {
//     item.data.forEach((itemS) => {
//       if (country === itemS.twoCharCode.toLowerCase()) {
//         countryInfo = itemS;
//       }
//     });
//   });
//   store.commit('base/setCountryCode', country); // 获取国家
//   console.log(store.state.base.CountryCode, 'CountryCode');
//   store.commit('base/setInfo', countryInfo); // 获取国家code，国家名称，国家代码，国旗
//   store.commit('base/setuserCountInfo', countryInfo); // 用户中心获取国家code，国家名称，国家代码，国旗
// }

function getIp() {
  try {
    axios({
      url: 'https://exportedbase.interface003.com/ip',
      timeout: 10000
    })
      .then(async (result) => {
        const ipinfo = result?.data?.result || {};
        const { ip, country_code } = ipinfo;

        if (ip) {
          store.commit('base/getIp', ip);
          store.commit('base/setIpInfo', ipinfo);
          const ccode = country_code === 'gb' ? 'uk' : country_code;
          store.commit('base/setCountryCode', ccode); // 获取国家
          console.log('国家code', ccode);
          console.log('ip::: ', ip);
          console.log('ipinfo::: ', ipinfo);
        }
      })
      .catch(() => {
        store.commit('base/set_is404', true);
      });
  } catch (error) {
    store.commit('base/set_is404', true);
  }
}

function init() {
  setBfp();
  getIp();
  // setcountryInfo();
}
init(); // 执行
